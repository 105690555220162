<template>
    <div class="col d-sm-flex justify-content-center">
        <div class="card">
            <div v-if="!isSchoolEnabled()">
                <h3>Per acquistare le ore di guida puoi recarti presso la tua autoscuola.</h3>
                <p></p>
                <p><b-button variant="primary" @click="$router.go(-1)">Torna indietro</b-button></p>
            </div>
            <div v-if="!submitted && school && profile && isSchoolEnabled()">
                <b-form @submit.stop.prevent="startPurchase">

                    <div class="row">
                        <div class="col-sm-6">
                            <button class="btn btn-primary big-selector" :disabled="(purchase.item == 'Ore guida reali')"
                                    @click="setItem('Ore guida reali')">
                                <br>
                                <h5 style="font-weight: lighter">Guide con instruttore e veicolo</h5>
                                <br>
                                <h2>{{ prices[0].price | toCurrency }}</h2>
                                <br>
                            </button>
                        </div>
                        <div class="col-sm-6">
                            <button class="btn btn-primary big-selector"
                                    :disabled="(purchase.item == 'Ore guida simulatore')"
                                    @click="setItem('Ore guida simulatore')">
                                <br>
                                <h5 style="font-weight: lighter">Guide con simulatore</h5>
                                <br>
                                <h2>{{ prices[1].price | toCurrency }}</h2>
                                <br>
                            </button>
                        </div>
                    </div>

                    <br>
                    <b-form-group id="qty" label="Quantità" label-for="qty" description="" v-if="purchase.item">
                        <b-form-select v-model="purchase.qty" :state="validateState('qty')">
                            <option value="0">- Scegli la quantità -</option>
                            <option v-for="qty in qties" v-bind:value="qty" :key="qty">
                                {{ qty }}
                            </option>
                        </b-form-select>
                    </b-form-group>

                    <br>
                    <br>

                    <h2 v-if="purchase.qty">TOTALE: {{ purchase.total | toCurrency }}</h2>

                    <hr>

                    <div v-if="purchase.total">
                        <div v-if="profileHasData() && !editProfile">
                            <h5>Dati per la fatturazione  <b-button size="sm" variant="link" @click="editProfile = true"><b-icon icon="pencil"/> modifica</b-button></h5>
                            <p><b>Nome:</b> {{ profile.firstname }} {{ profile.lastname }}<br/>
                                <b>Indirizzo:</b> {{ profile.address }} - {{ profile.cap }} {{ profile.city }} ({{ profile.province }})<br/>
                                <b>Codice Fiscale:</b> {{ profile.fiscalCode }}</p>

                        </div>
                        <div v-else>
                            <h5>Inserisci i tuoi dati per la fatturazione</h5>
                            <purchase-profile-data :profile="profile"
                                        v-on:updated-profile="editProfile = false"
                                        v-on:close-profile-edit="editProfile = false"/>
                        </div>
                    </div>
                    <br>

                    <div class="action-bar" v-if="purchase.total && profileHasData() && !editProfile">
                        <b-button size="lg" variant="success" block @click="startPurchase" :disabled="this.$store.state.loading" style="font-size: 40px">
                            Acquista con PayPal
                        </b-button>
                    </div>

                </b-form>

                <div>
                    <br>
                    <b-alert variant="success" :show="message != ''" fade>
                        <b-icon icon="hand-thumbs-up"/>
                        {{ message }}
                    </b-alert>
                    <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
                </div>
            </div>
            <div v-if="submitted && school && profile && isSchoolEnabled()" class="text-center">
                <p>Ti stiamo redirezionando sul sito PayPal</p>
                <img src="../../assets/paypal-lock.gif" width="160"/>
            </div>
        </div>
    </div>
</template>

<script>
import PurchaseDataService from "@/components/purchase/PurchaseDataService";
import SchoolDataService from "@/components/school/SchoolDataService";
import {required} from 'vuelidate/lib/validators'
import ProfileDataService from "@/components/profile/ProfileDataService";
import AuthDataService from "@/components/auth/AuthDataService";
import PurchaseProfileData from "@/components/purchase/PurchaseProfileData";

export default {
    name: "purchase-available-time",
    components: {PurchaseProfileData},
    data() {
        return {
            purchase: {
                item: '',
                qty: 0,
                price: 0,
                total: 0,
                customerName: '',
                customerAddress: '',
                customerCF: '',
            },
            profile: null,
            submitted: false,
            message: '',
            errorMsg: '',
            school: null,
            qties: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 50],
            prices: [],
            editProfile: false
        }
    },
    validations: {
        purchase: {
            item: {
                required,
            },
            qty: {
                required,
            },
            price: {
                required,
            },
            total: {
                required
            },
        }
    },
    methods: {
        validateState(name) {
            const {$dirty, $error} = this.$v.purchase[name];
            return $dirty ? !$error : null;
        },
        startPurchase() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                // retrieve profile info
                this.purchase.customerName = this.profile.firstname + ' ' + this.profile.lastname;
                this.purchase.customerAddress = this.profile.address + ' - ' + this.profile.cap + ' ' + this.profile.city + ' (' + this.profile.province + ')';
                this.purchase.customerCF = this.profile.fiscalCode;
                console.log(this.purchase)

                PurchaseDataService.create(this.purchase)
                    .then(response => {
                        //this.purchase.id = response.data.id;
                        if (response.data.redirectUrl) location.href = response.data.redirectUrl;
                        this.submitted = true;
                        //this.$emit('created-practice');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        profileHasData() {
            return this.profile.address && this.profile.cap && this.profile.city && this.profile.province && this.profile.fiscalCode;
        },
        setItem(item) {
            this.purchase.item = item;
        },
        setPrice(price) {
            this.purchase.price = price;
        },
        setTotal() {
            this.purchase.total = this.purchase.price * this.purchase.qty;
        },
        isSchoolEnabled() {
            if (this.school) return this.school.paypalClientSecret
                && this.school.paypalClientId
                && this.school.practicePriceHour
                && this.school.simulatorPriceHour;
        }
    },
    mounted() {
        const schoolId = this.$store.state.schoolId;
        SchoolDataService.get(schoolId)
            .then(response => {
                this.school = response.data;
                this.prices.push({
                    itemName: 'Ore guida reali',
                    price: this.school.practicePriceHour
                });
                this.prices.push({
                    itemName: 'Ore guida simulatore',
                    price: this.school.simulatorPriceHour
                });
            })
            .catch(e => {
                this.errorMsg = 'Non hai compilato tutti i campi';
                console.log(e);
            });

        const profileId = this.$store.state.user.profileId;
        AuthDataService.me()
            .then(response => {
                this.profile = response.data.profile;
            })
            .catch(e => {
                this.errorMsg = 'Non hai compilato tutti i campi';
                console.log(e);
            });
    },
    filters: {
        currency: function (value) {
            return '€ ' + value.replace('.', ',');
        },
    },
    watch: {
        'purchase.item': function (newVal, oldVal) {
            let result = this.prices.find(i => i.itemName == newVal);
            this.setPrice(result.price)
            this.setTotal()
        },
        'purchase.qty': function (newVal, oldVal) {
            this.setTotal(newVal)
        },
        deep: true
    },
};
</script>

<style scoped>
.big-selector {
    text-align: center;
    width: 100%;
}
@media only screen and (min-width: 750px) {
    .card {
        min-width: 750px;
        margin: 25px;
    }
}
</style>