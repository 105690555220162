<template>
    <div v-if="currentProfile">
        <b-row>
            <b-col md="6">
                <b-form-group id="firstname" label="Nome" label-for="firstname" description="">
                    <b-form-input v-model="currentProfile.firstname" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group id="lastname" label="Cognome" label-for="lastname" description="">
                    <b-form-input v-model="currentProfile.lastname" readonly></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-form-group id="birthDate" label="Data di nascita" label-for="birthDate" description="">
            <b-form-input type="date" v-model="currentProfile.birthDate" readonly></b-form-input>
        </b-form-group>

        <b-form-group id="firstname" label="Indirizzo" label-for="firstname" description="">
            <b-form-input v-model="currentProfile.address" :state="validateState('address')"></b-form-input>
        </b-form-group>

        <b-row>
            <b-col md="3">
                <b-form-group id="cap" label="CAP" label-for="cap" description="">
                    <b-form-input v-model="currentProfile.cap" :state="validateState('cap')"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group id="city" label="Città" label-for="city" description="">
                    <b-form-input v-model="currentProfile.city" :state="validateState('city')"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="3">
                <b-form-group id="province" label="Provincia" label-for="province" description="">
                    <b-form-select v-model="currentProfile.province" :state="validateState('province')">
                        <option value="0">- Scegli una provincia -</option>
                        <option v-for="province in provinces" v-bind:value="province.sigla" :key="province.sigla">
                            {{ province.nome }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-form-group id="fiscalCode" label="Codice Fiscale" label-for="fiscalCode" description="">
            <b-form-input v-model="currentProfile.fiscalCode" :state="validateState('fiscalCode')"></b-form-input>
        </b-form-group>

        <b-row>
            <b-col>
                <a class="btn btn-success" @click="updateProfile" :disabled="this.$store.state.loading">
                    Salva dati per la fatturazione
                </a>
            </b-col>
            <b-col class="text-right">
                <button class="btn btn-link" @click="close" :disabled="this.$store.state.loading">
                    <b-icon icon="x"/> Chiudi e torna alla procedura di acquisto
                </button>
            </b-col>
        </b-row>


    </div>
</template>

<script>
import StudentDataService from "@/components/student/StudentDataService";
import {required} from 'vuelidate/lib/validators'

const myFiscalCode = (value) => value.length == 16;

export default {
    name: "purchase-profile-data",
    data() {
        return {
            currentProfile: null,
            provinces: [
                {
                    "nome": "Agrigento",
                    "sigla": "AG",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Alessandria",
                    "sigla": "AL",
                    "regione": "Piemonte"
                },
                {
                    "nome": "Ancona",
                    "sigla": "AN",
                    "regione": "Marche"
                },
                {
                    "nome": "Arezzo",
                    "sigla": "AR",
                    "regione": "Toscana"
                },
                {
                    "nome": "Ascoli Piceno",
                    "sigla": "AP",
                    "regione": "Marche"
                },
                {
                    "nome": "Asti",
                    "sigla": "AT",
                    "regione": "Piemonte"
                },
                {
                    "nome": "Avellino",
                    "sigla": "AV",
                    "regione": "Campania"
                },
                {
                    "nome": "Bari",
                    "sigla": "BA",
                    "regione": "Puglia"
                },
                {
                    "nome": "Barletta-Andria-Trani",
                    "sigla": "BT",
                    "regione": "Puglia"
                },
                {
                    "nome": "Belluno",
                    "sigla": "BL",
                    "regione": "Veneto"
                },
                {
                    "nome": "Benevento",
                    "sigla": "BN",
                    "regione": "Campania"
                },
                {
                    "nome": "Bergamo",
                    "sigla": "BG",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Biella",
                    "sigla": "BI",
                    "regione": "Piemonte"
                },
                {
                    "nome": "Bologna",
                    "sigla": "BO",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Bolzano/Bozen",
                    "sigla": "BZ",
                    "regione": "Trentino-Alto Adige/Südtirol"
                },
                {
                    "nome": "Brescia",
                    "sigla": "BS",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Brindisi",
                    "sigla": "BR",
                    "regione": "Puglia"
                },
                {
                    "nome": "Cagliari",
                    "sigla": "CA",
                    "regione": "Sardegna"
                },
                {
                    "nome": "Caltanissetta",
                    "sigla": "CL",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Campobasso",
                    "sigla": "CB",
                    "regione": "Molise"
                },
                {
                    "nome": "Carbonia-Iglesias",
                    "sigla": "CI",
                    "regione": "Sardegna"
                },
                {
                    "nome": "Caserta",
                    "sigla": "CE",
                    "regione": "Campania"
                },
                {
                    "nome": "Catania",
                    "sigla": "CT",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Catanzaro",
                    "sigla": "CZ",
                    "regione": "Calabria"
                },
                {
                    "nome": "Chieti",
                    "sigla": "CH",
                    "regione": "Abruzzo"
                },
                {
                    "nome": "Como",
                    "sigla": "CO",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Cosenza",
                    "sigla": "CS",
                    "regione": "Calabria"
                },
                {
                    "nome": "Cremona",
                    "sigla": "CR",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Crotone",
                    "sigla": "KR",
                    "regione": "Calabria"
                },
                {
                    "nome": "Cuneo",
                    "sigla": "CN",
                    "regione": "Piemonte"
                },
                {
                    "nome": "Enna",
                    "sigla": "EN",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Fermo",
                    "sigla": "FM",
                    "regione": "Marche"
                },
                {
                    "nome": "Ferrara",
                    "sigla": "FE",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Firenze",
                    "sigla": "FI",
                    "regione": "Toscana"
                },
                {
                    "nome": "Foggia",
                    "sigla": "FG",
                    "regione": "Puglia"
                },
                {
                    "nome": "Forlì-Cesena",
                    "sigla": "FC",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Frosinone",
                    "sigla": "FR",
                    "regione": "Lazio"
                },
                {
                    "nome": "Genova",
                    "sigla": "GE",
                    "regione": "Liguria"
                },
                {
                    "nome": "Gorizia",
                    "sigla": "GO",
                    "regione": "Friuli-Venezia Giulia"
                },
                {
                    "nome": "Grosseto",
                    "sigla": "GR",
                    "regione": "Toscana"
                },
                {
                    "nome": "Imperia",
                    "sigla": "IM",
                    "regione": "Liguria"
                },
                {
                    "nome": "Isernia",
                    "sigla": "IS",
                    "regione": "Molise"
                },
                {
                    "nome": "L'Aquila",
                    "sigla": "AQ",
                    "regione": "Abruzzo"
                },
                {
                    "nome": "La Spezia",
                    "sigla": "SP",
                    "regione": "Liguria"
                },
                {
                    "nome": "Latina",
                    "sigla": "LT",
                    "regione": "Lazio"
                },
                {
                    "nome": "Lecce",
                    "sigla": "LE",
                    "regione": "Puglia"
                },
                {
                    "nome": "Lecco",
                    "sigla": "LC",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Livorno",
                    "sigla": "LI",
                    "regione": "Toscana"
                },
                {
                    "nome": "Lodi",
                    "sigla": "LO",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Lucca",
                    "sigla": "LU",
                    "regione": "Toscana"
                },
                {
                    "nome": "Macerata",
                    "sigla": "MC",
                    "regione": "Marche"
                },
                {
                    "nome": "Mantova",
                    "sigla": "MN",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Massa-Carrara",
                    "sigla": "MS",
                    "regione": "Toscana"
                },
                {
                    "nome": "Matera",
                    "sigla": "MT",
                    "regione": "Basilicata"
                },
                {
                    "nome": "Medio Campidano",
                    "sigla": "VS",
                    "regione": "Sardegna"
                },
                {
                    "nome": "Messina",
                    "sigla": "ME",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Milano",
                    "sigla": "MI",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Modena",
                    "sigla": "MO",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Monza e della Brianza",
                    "sigla": "MB",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Napoli",
                    "sigla": "NA",
                    "regione": "Campania"
                },
                {
                    "nome": "Novara",
                    "sigla": "NO",
                    "regione": "Piemonte"
                },
                {
                    "nome": "Nuoro",
                    "sigla": "NU",
                    "regione": "Sardegna"
                },
                {
                    "nome": "Ogliastra",
                    "sigla": "OG",
                    "regione": "Sardegna"
                },
                {
                    "nome": "Olbia-Tempio",
                    "sigla": "OT",
                    "regione": "Sardegna"
                },
                {
                    "nome": "Oristano",
                    "sigla": "OR",
                    "regione": "Sardegna"
                },
                {
                    "nome": "Padova",
                    "sigla": "PD",
                    "regione": "Veneto"
                },
                {
                    "nome": "Palermo",
                    "sigla": "PA",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Parma",
                    "sigla": "PR",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Pavia",
                    "sigla": "PV",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Perugia",
                    "sigla": "PG",
                    "regione": "Umbria"
                },
                {
                    "nome": "Pesaro e Urbino",
                    "sigla": "PU",
                    "regione": "Marche"
                },
                {
                    "nome": "Pescara",
                    "sigla": "PE",
                    "regione": "Abruzzo"
                },
                {
                    "nome": "Piacenza",
                    "sigla": "PC",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Pisa",
                    "sigla": "PI",
                    "regione": "Toscana"
                },
                {
                    "nome": "Pistoia",
                    "sigla": "PT",
                    "regione": "Toscana"
                },
                {
                    "nome": "Pordenone",
                    "sigla": "PN",
                    "regione": "Friuli-Venezia Giulia"
                },
                {
                    "nome": "Potenza",
                    "sigla": "PZ",
                    "regione": "Basilicata"
                },
                {
                    "nome": "Prato",
                    "sigla": "PO",
                    "regione": "Toscana"
                },
                {
                    "nome": "Ragusa",
                    "sigla": "RG",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Ravenna",
                    "sigla": "RA",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Reggio di Calabria",
                    "sigla": "RC",
                    "regione": "Calabria"
                },
                {
                    "nome": "Reggio nell'Emilia",
                    "sigla": "RE",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Rieti",
                    "sigla": "RI",
                    "regione": "Lazio"
                },
                {
                    "nome": "Rimini",
                    "sigla": "RN",
                    "regione": "Emilia-Romagna"
                },
                {
                    "nome": "Roma",
                    "sigla": "RM",
                    "regione": "Lazio"
                },
                {
                    "nome": "Rovigo",
                    "sigla": "RO",
                    "regione": "Veneto"
                },
                {
                    "nome": "Salerno",
                    "sigla": "SA",
                    "regione": "Campania"
                },
                {
                    "nome": "Sassari",
                    "sigla": "SS",
                    "regione": "Sardegna"
                },
                {
                    "nome": "Savona",
                    "sigla": "SV",
                    "regione": "Liguria"
                },
                {
                    "nome": "Siena",
                    "sigla": "SI",
                    "regione": "Toscana"
                },
                {
                    "nome": "Siracusa",
                    "sigla": "SR",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Sondrio",
                    "sigla": "SO",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Taranto",
                    "sigla": "TA",
                    "regione": "Puglia"
                },
                {
                    "nome": "Teramo",
                    "sigla": "TE",
                    "regione": "Abruzzo"
                },
                {
                    "nome": "Terni",
                    "sigla": "TR",
                    "regione": "Umbria"
                },
                {
                    "nome": "Torino",
                    "sigla": "TO",
                    "regione": "Piemonte"
                },
                {
                    "nome": "Trapani",
                    "sigla": "TP",
                    "regione": "Sicilia"
                },
                {
                    "nome": "Trento",
                    "sigla": "TN",
                    "regione": "Trentino-Alto Adige/Südtirol"
                },
                {
                    "nome": "Treviso",
                    "sigla": "TV",
                    "regione": "Veneto"
                },
                {
                    "nome": "Trieste",
                    "sigla": "TS",
                    "regione": "Friuli-Venezia Giulia"
                },
                {
                    "nome": "Udine",
                    "sigla": "UD",
                    "regione": "Friuli-Venezia Giulia"
                },
                {
                    "nome": "Valle d'Aosta/Vallée d'Aoste",
                    "sigla": "AO",
                    "regione": "Valle d'Aosta/Vallée d'Aoste"
                },
                {
                    "nome": "Varese",
                    "sigla": "VA",
                    "regione": "Lombardia"
                },
                {
                    "nome": "Venezia",
                    "sigla": "VE",
                    "regione": "Veneto"
                },
                {
                    "nome": "Verbano-Cusio-Ossola",
                    "sigla": "VB",
                    "regione": "Piemonte"
                },
                {
                    "nome": "Vercelli",
                    "sigla": "VC",
                    "regione": "Piemonte"
                },
                {
                    "nome": "Verona",
                    "sigla": "VR",
                    "regione": "Veneto"
                },
                {
                    "nome": "Vibo Valentia",
                    "sigla": "VV",
                    "regione": "Calabria"
                },
                {
                    "nome": "Vicenza",
                    "sigla": "VI",
                    "regione": "Veneto"
                },
                {
                    "nome": "Viterbo",
                    "sigla": "VT",
                    "regione": "Lazio"
                }
            ]
        }
    },
    validations: {
        currentProfile: {
            address: { required },
            city: { required },
            cap: { required },
            province: { required },
            fiscalCode: { required, myFiscalCode },
        }
    },
    props: ['profile'],
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.currentProfile[name];
            return $dirty ? !$error : null;
        },
        updateProfile() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                const data = {
                    address: this.currentProfile.address,
                    cap: this.currentProfile.cap,
                    city: this.currentProfile.city,
                    province: this.currentProfile.province,
                    fiscalCode: this.currentProfile.fiscalCode
                }
                //
                StudentDataService.update(this.profile.id, data)
                    .then(response => {
                        this.profile.address = this.currentProfile.address;
                        this.profile.cap = this.currentProfile.cap;
                        this.profile.city = this.currentProfile.city;
                        this.profile.province = this.currentProfile.province;
                        this.profile.fiscalCode = this.currentProfile.fiscalCode;
                        this.$emit('updated-profile');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        close() {
            this.$emit('close-profile-edit');
        }
    },
    mounted() {
        this.currentProfile = JSON.parse(JSON.stringify(this.profile))
        //this.currentProfile = this.profile;
    }
}
</script>

<style scoped>

</style>